import React from 'react'

import family from '../../../images/family-hug.png'

const UseInsurance = (alt = false) => {
  if (alt) return null
  return (
    <div className="use-insurance bg-white">
      <div className="container">
        <div className="w-50 w-100-s content">
          <div className="w-80 w-100-s pt5 pt4-m">
            <h2>What type of life insurance is right for me?</h2>
            <p>
              Depending on your situation, you may or may not require certain types of
              coverage. Additionally, there’s several options when it comes to life
              insurance. From term life, whole life and final expense life it all depends.
              Let us help you find what's best for you.
            </p>
          </div>
        </div>
        <div
          className="w-50 w-100-s bg-img"
          style={{ backgroundImage: `url('${family}')` }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  )
}

export default UseInsurance
