import React from 'react'

const Faqs = () => {
  return (
    <div className="bg-white section base-section faq-section alt">
      <div className="container container-md">
        <div className="section-heading text-center">
          <h2>Frequently Asked Questions</h2>
        </div>
        <ul className="faq-list pt3 ">
          <li>
            <h3 className="mb0">
              <a href="#" className="opener">
                Why should I use LifeInsuranceCompanion.com?
              </a>
            </h3>
            <div className="slide">
              <p>
                LifeInsuranceCompanion.com helps you learn about your Life insurance
                coverage options and find a plan, all at once. A licensed agent can answer
                your questions and help you find the right Life insurance plan to fit your
                needs and budget.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener">
                Do I have to pay for your help?
              </a>
            </h3>
            <div className="slide">
              <p>
                No, we provide our services at no charge to you. Agents are happy to
                answer your Life-related questions, help you compare your plan options,
                and decide if Life insurance is right for you.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener">
                What type of Life insurance is right for me?
              </a>
            </h3>
            <div className="slide">
              <p>
                Depending on your situation, you may or may not require certain types of
                coverage. Additionally, there’s several options when it comes to Life
                insurance. Let us help you find what's best for you.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener">
                How do I know I'm getting the best rate?
              </a>
            </h3>
            <div className="slide">
              <p>
                With Life Insurance Companion you can easily compare quotes for Life
                insurance carriers. Allowing you to compare prices to fit your budget.
                After all, saving a little money is never a bad thing.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener">
                How do I get started?
              </a>
            </h3>
            <div className="slide">
              <p>
                You can get started by filling out the form at the top of this page or by
                calling a licensed agent directly at{' '}
                <a href="tel:18667402901">1-877-755-6327</a>. We can help you through the
                entire process, from finding the right plan to submitting your
                application.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Faqs
