import React from 'react'

import Benefits from '../benefits'
import Cta from '../cta'
import Faqs from '../faqs'
import Testimonials from '../testimonials'
import UseInsurance from '../use-insurance'

const HomeContent = ({ hideCta = false, hideTestimonials = false }) => {
  return (
    <>
      <Benefits />
      <UseInsurance />
      {!hideTestimonials && <Testimonials />}
      <Faqs />
      {!hideCta && <Cta />}
    </>
  )
}

export default HomeContent
