import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const Partners = ({ alt2 = false }) => {
  return (
    <div
      className={clsx(
        'partners-section',
        'bg-orange-light',
        'alt',
        alt2 && 'alt2',
        styles.temp,
      )}
    />
  )
  return (
    <div className={clsx('partners-section', 'bg-orange-light', 'alt', alt2 && 'alt2')}>
      <div className="container">
        <ul className={clsx('partners-list', styles.center)}>
          {/* <li>
            <img src="/images/kemper.png" alt="Kemper" />
          </li> */}
          {/* <li>
            <img src="/images/national-guardian.png" alt="National Guardian" />
          </li> */}
          <li>
            <img src="/images/mutual-omaha.png" alt="Mutual of Omaha" />
          </li>
          {/* <li style={{ marginTop: '-20px' }}>
            <img src="/images/transamerica.png" alt="Transamerica" />
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Partners
