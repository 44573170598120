import PropTypes from 'prop-types'
import React from 'react'

import logo from '../../images/logo.svg'
import logoGray from '../../images/logo-gray.svg'
import logoAlt from '../../images/logo-white.svg'
import circle from '../../icons/circle.svg'

const Header = ({
  phone,
  hideCta,
  showGen,
  className,
  grayLogo,
  altLogo,
  buttonClassName,
}) => {
  const tel = phone.replace('-', '').replace('-', '')
  const headerWrapperClassNames = hideCta ? 'header-wrapper cta-hidden' : 'header-wrapper'
  const buttonClassNames = `${buttonClassName} btn with-icon`

  let headerLogo = grayLogo ? logoGray : logo
  headerLogo = altLogo ? logoAlt : headerLogo

  return (
    <div className="top-wrapper">
      {showGen && (
        <div className="gen3">
          <div className="container">
            <div className="g3cm_holder" data-module="1259" data-showoninit="true">
              <p>
                A live agent in your area is standing by. For your free quote, call now.
              </p>
              <a href={`tel:${tel}`} className="btn with-icon">
                <span className="icon-phone" /> <span className="text">{phone}</span>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className={headerWrapperClassNames}>
        <header id="header" className={className}>
          <div className="container">
            <strong className="logo">
              <img src={headerLogo} alt="LifeInsuranceCompanion.com" />
            </strong>
            {!hideCta && (
              <div className="cta-holder">
                <span className="text hidden-tablet">
                  <strong>Need Help?</strong> Speak with a <br />
                  Licensed Insurance Agent.
                </span>
                <a href={`tel:${tel}`} className={buttonClassNames}>
                  <span className="icon-phone" /> <span className="text">{phone}</span>
                </a>
              </div>
            )}
          </div>
        </header>
      </div>
    </div>
  )
}

Header.propTypes = {
  phone: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  hideCta: PropTypes.bool,
  showGen: PropTypes.bool,
  grayLogo: PropTypes.bool,
  altLogo: PropTypes.bool,
}

Header.defaultProps = {
  phone: ``,
  className: ``,
  buttonClassName: ``,
  hideCta: false,
  showGen: false,
  grayLogo: false,
  altLogo: false,
}

export default Header
